<template>
  <!-- <VisualItem :visualKeys="[tip.visualKeys, tipImg.visualKeys, tipImg_2x.visualKeys, popoTip.visualKeys]" @visual="handleVisual" class-name="g-r">
    <div v-if="tip.value" class="m-tip">
      {{ tip.value }}
      <div class="m-popo-tip">
        <CMSImage
          :img="tipImg ? tipImg.value : ''"
          :img2x="tipImg_2x ? tipImg_2x.value : ''"
        />
        <div class="u-popo-tip">
          {{ popoTip.value }}
        </div>
      </div>
    </div>
  </VisualItem> -->
  <VisualItem :visualKeys="[themeColor.visualKeys, isShowTip.visualKeys]" @visual="handleVisual">
    <div
      :class="['m-number-header', isPC ? 'z-web' : 'z-phone',
               { 'z-visual': cmsVisual, 'z-fill': top > 0 },
               showTab && !isPC ? 'z-fill' : '',
               theme.primaryColor ? theme.primaryColor : 'blue',
               themeColor && themeColor.value ? 'block' : 'white'
      ]"
    >
      <div v-if="isPC" class="g-content f-clearfix">
        <div class="m-logo g-l">
          <a href="/" class="f-clearfix">
            <VisualItem
              :visualKeys="[logo.visualKeys, logo_2x.visualKeys, logo_white.visualKeys, logo_white_2x.visualKeys]"
              @visual="handleVisual"
              class-name="logo-item u-logo"
            >
              <CMSImage
                v-if="themeColor.value || top > 0 && (logo && logo.value) && (logo_2x && logo_2x.value)"
                :img="logo ? logo.value : ''"
                :img2x="logo_2x ? logo_2x.value : ''"
              />
              <CMSImage
                v-else-if="(logo_white && logo_white.value) && (logo_white_2x && logo_white_2x.value)"
                :img="logo_white ? logo_white.value : ''"
                :img2x="logo_white_2x ? logo_white_2x.value : ''"
              />
            </VisualItem>
          </a>
        </div>
        <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual" class-name="m-list f-clearfix">
          <VisualItem
            v-for="(item, index) in list.value"
            :key="index"
            :visualKeys="[item.title.visualKeys, item.link.visualKeys]"
            @visual="handleVisual"
            class-name="g-l"
          >
            <CMSLink
              :link="item.link.value"
              :class-name="['m-menu', ((item.link.value !== '/' && path.indexOf(item.link.value) === 0) || path === item.link.value) ? 'z-selected' : '']"
            >
              {{ item.title.value }}
              <div v-if="item.tag.value" class="m-tag">
                {{ item.tag.value }}
              </div>
            </CMSLink>
          </VisualItem>
        </VisualItem>
        <VisualItem :visualKeys="[btn.visualKeys, btnLink.visualKeys]" @visual="handleVisual" class-name="g-r">
          <CMSLink
            :link="btnLink.value"
            class-name="u-btn"
          >
            {{ btn.value }}
          </CMSLink>
        </VisualItem>
      </div>
      <div v-else class="g-content f-clearfix">
        <div class="m-logo g-l">
          <a href="/" class="f-clearfix">
            <VisualItem :visualKeys="[logo_2x_h5.visualKeys, logo_3x_h5.visualKeys]" @visual="handleVisual" class-name="logo-item u-logo blue">
              <CMSImage
                v-if="(logo_2x_h5 && logo_2x_h5.value) && (logo_3x_h5 && logo_3x_h5.value)"
                :img="logo_2x_h5 ? logo_2x_h5.value : logo_2x_h5.value"
                :img2x="logo_3x_h5 ? logo_3x_h5.value : logo_3x_h5.value"
              />
            </VisualItem>
          </a>
        </div>
        <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual" :class-name="['m-list', showTab ? 'm-list-showTab' : '']">
          <div @click="showTab = false" class="closeTab" />
          <div :class="['g-list', showTab ? 'g-list-showTab' : '']">
            <VisualItem
              v-for="(item, index) in list.value"
              :key="index"
              :visualKeys="[item.title.visualKeys, item.link.visualKeys]"
              @visual="handleVisual"
              class-name="g-title"
            >
              <CMSLink
                :link="item.link.value"
                :class-name="['m-menu', ((item.link.value !== '/' && path.indexOf(item.link.value) === 0) || path === item.link.value) ? 'z-selected' : '', path.indexOf('main_activity') === 1 ? 'main-activity' : '']"
              >
                {{ item.title.value }}
              </CMSLink>
            </VisualItem>
          </div>
        </VisualItem>
        <VisualItem v-if="btn.value" :visualKeys="[btn.visualKeys, btnLink.visualKeys]" @visual="handleVisual" class-name="g-r">
          <CMSLink
            :link="btnLink.value"
            class-name="u-btn"
          >
            {{ btn.value }}
          </CMSLink>
        </VisualItem>
        <div v-show="!showTab && (top === 0 && path.length<=2)" @click="showHandal" class="more">
          <img src="/WebsiteHeader/open.svg">
        </div>
        <div v-show="!showTab && (top > 0 || path.length > 2)" @click="showHandal" class="more">
          <img src="/WebsiteHeader/open.svg">
        </div>
        <div v-show="showTab" @click="showHandal" class="more">
          <img src="/WebsiteHeader/close.svg">
        </div>
      </div>
      <VisualItem
        :visualKeys="[contactUsLink.visualKeys]"
        @visual="handleVisual"
      >
        <CMSLink
          :link="contactUsLink.value"
        >
          <img class="contact-us" src="/NewsBanner/contactUs.svg" alt="">
        </CMSLink>
      </VisualItem>
    </div>
  </VisualItem>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import CMSLink from '~/components/common/Link'
import VisualItem from '~/components/common/VisualItem'
import CMSImage from '~/components/common/Image'

const componentKey = 'NumberHeaderWithLogin'

export default {
  components: {
    CMSLink,
    VisualItem,
    CMSImage
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(_.cloneDeep(config), componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    // console.log(componentKey)
    // console.log(temp)

    return {
      ...data,
      top: 0,
      showTab: false
    }
  },
  computed: {
    cmsVisual() {
      return this.$store.state.cmsVisual
    },
    theme() {
      return this.$store.state.theme
    },
    path() {
      return this.$nuxt.$route.path
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.reset()
    })

    this.hostname = location.hostname
    this.reset()
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    },
    reset() {
      this.top = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    },
    showHandal() {
      this.showTab = !this.showTab
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-number-header {
  &.z-phone {
    position: sticky;
    top: 0;
    height: pxtorem(96);
    width: 100%;
    z-index: 997;

    .g-content {
      width: 100%;
      margin: 0 auto;
      height: pxtorem(96);
      position: fixed;
      background: #fff;
      padding: 0 pxtorem(40);
      z-index: 997;

      .g-r {
        width: pxtorem(240);
        margin-top: pxtorem(24);
      }

      .more {
        position: absolute;
        top: pxtorem(30);
        right: pxtorem(24);
        width: pxtorem(70);
        text-align: center;
        height: pxtorem(40);
      }
    }

    .m-logo {
      line-height: pxtorem(84);
      font-size: pxtorem(92);
      width: 4em;

      & > a {
        display: block;
      }

      .logo-item {
        overflow: hidden;

        img {
          max-width: pxtorem(204);
          vertical-align: baseline;
        }
      }
    }

    .m-list {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.298487);
      position: fixed;
      top: pxtorem(96);
      right: 100%;
      bottom: 0;

      .closeTab {
        width: calc(100% - pxtorem(560));
        height: 100%;
        position: fixed;
        bottom: 0;
      }

      .g-list {
        width: calc(100% - pxtorem(190));
        height: 100%;
        background: #fff;
        padding-top: pxtorem(28);
        position: fixed;
        right: pxtorem(-560);
        text-align: center;
        transition: 300ms;

        .g-title {
          line-height: pxtorem(84);
          margin-bottom: pxtorem(28);

          &:nth-child(3) > .main-activity {
            color: $baseBlue;
            background: #f3f6fc;
          }

          .m-menu {
            display: block;
            width: 100%;
            color: #333;
          }

          .z-selected {
            background: #f3f6fc;
            color: $baseBlue;
          }
        }
      }

      .g-list-showTab {
        height: 100%;
        position: fixed;
        right: 0;
      }
    }

    .m-list-showTab {
      position: fixed;
      right: 0;
    }

    &.z-fill {
      background: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      color: #333;

      .m-logo {
        .u-logo {
          &.white {
            display: none;
          }

          &.blue {
            display: block;
          }
        }
      }
    }

    .u-btn {
      max-width: pxtorem(168);
      height: pxtorem(50);
      font-size: pxtorem(22);
      line-height: pxtorem(52);
      text-align: center;
      display: block;
      color: #fff;
      background-color: $baseBlue;
      padding: 0 pxtorem(12);
    }

    .contact-us {
      position: fixed;
      right: 0;
      top: 88vh;
      margin-top: -85px;
    }

    &.red {
      .g-list .g-title .z-selected {
        color: $baseRed;
        background-color: #fffafa;
      }

      .u-btn {
        background: $baseRed;
      }

      .g-list .g-title:nth-child(3) > .main-activity {
        color: $baseRed;
        background: #fffafa;
      }
    }
  }

  &.z-web {
    position: fixed;
    top: 0;
    height: 66px;
    min-width: 1200px;
    width: 100%;
    z-index: 997;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.03);
    border-bottom: 1px solid #e7e7e7;
    box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.005), inset 0 0 68px rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(20px);

    .m-list {
      padding: 0 20px;

      .m-tag {
        background: rgba(0, 0, 255, 0.9);
        height: 16px;
        line-height: 16px;
        width: 55px;
        border-radius: 2px 2px 2px 0;
        font-size: 12px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 9px;
        right: -42px;
      }
    }

    .contact-us {
      position: fixed;
      right: 0;
      top: 50vh;
      margin-top: -85px;
    }

    &.z-fill {
      background: #fff;

      .m-list {
        .m-menu {
          color: #000;
          opacity: 1;

          &.z-selected {
            color: $diBlue;
          }
        }
      }
    }

    .g-content {
      margin: 0 auto;
      text-align: center;
      min-width: 1200px;

      .u-btn {
        display: block;
        color: #fff;
        background-color: $diBlue;
        line-height: 64px;
        padding: 0 23px;

        &:hover {
          background-color: $diBlue;
        }

        &:active {
          background-color: $diBlue;
        }
      }

      .m-tip {
        padding: 0 26px;
        height: 64px;
        line-height: 64px;
        margin-right: 25px;
        font-size: 14px;
        cursor: pointer;
        color: rgba(20, 32, 51, 1);

        &:hover {
          background: #f8f8fa;

          .m-popo-tip {
            visibility: visible;
          }
        }

        .m-popo-tip {
          position: absolute;
          top: 66px;
          padding: 7px 7px 12px;
          background: #fff;
          border-radius: 6px;
          text-align: center;
          transform: translateX(-35%);
          visibility: hidden;
          box-shadow: 0 10px 80px 10px rgba(1, 3, 10, 0.1);
          width: 194px;
          height: 225px;

          img {
            width: 180px;
            height: 180px;
          }

          .u-popo-tip {
            font-size: 12px;
            color: rgba(16, 32, 72, 0.55);
            margin-top: 8px;
            line-height: 12px;
          }
        }
      }
    }

    &.z-visual {
      top: 61px;
      left: 70px;
      right: 0;
      width: auto;
    }

    .m-logo {
      width: 256px;
      line-height: 66px;
      font-size: 66px;
      height: 66px;
      margin-right: 40px;

      & > a {
        display: block;
      }

      .u-logo {
        text-align: left;
      }

      .logo-item {
        overflow: hidden;
        line-height: 40px;
        width: 256px;
        height: 64px;
        position: relative;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .m-list {
      float: left;
      line-height: 66px;

      .m-menu {
        margin: 0 37px;
        font-size: 14px;
        color: #000;
        position: relative;
        display: block;

        &.z-selected {
          color: $diBlue;
        }
      }
    }

    &.white {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      .m-list {
        .m-menu {
          color: #fff;
          opacity: 0.7;

          &.z-selected {
            color: #fff;
            opacity: 1;
          }
        }

        .m-tag {
          opacity: 1;
        }
      }

      .g-content .m-tip {
        color: #fff;
        opacity: 0.7;

        &:hover {
          opacity: 1;
          background: none;
        }
      }

      &.z-fill {
        background: #fff;

        .m-list {
          .m-menu {
            color: #000;
            opacity: 1;

            &.z-selected {
              color: $diBlue;
            }
          }
        }

        .g-content .m-tip {
          color: #142033;

          &:hover {
            background: #f8f8fa;
          }
        }
      }
    }

    /* 页面风格变为红色时 */
    &.red {
      .m-menu {
        color: #333;
      }

      &.z-fill {
        .m-menu.z-selected {
          color: $baseRed;
        }

        .m-menu.z-selected::after {
          background-color: $baseRed;
        }

        .u-btn {
          &:hover {
            background-color: #c91417;
          }

          &:active {
            background-color: #bf0b0e;
          }
        }
      }

      .m-menu.z-selected {
        color: $baseRed;
      }

      .u-btn {
        color: #fff;
        background-color: $baseRed;
      }

      .m-menu.z-selected::after {
        background-color: $baseRed;
      }

      /* 单独为活动详情页设置导航栏样式 */
      .g-l .g-l:nth-child(2) {
        .main-activity {
          color: $baseRed;

          &::after {
            position: absolute;
            content: " ";
            width: 25px;
            height: 3px;
            background-color: $baseRed;
            left: 50%;
            margin-left: -12px;
            bottom: 3px;
          }
        }
      }
    }

    /* 页面风格变为蓝色时 */
    &.blue {
      .m-menu {
        color: #333;
      }

      &.z-fill {
        .m-menu.z-selected {
          color: $baseBlue;
        }

        .m-menu.z-selected::after {
          background-color: $baseBlue;
        }

        .u-btn {
          &:hover {
            background-color: #325cf3;
          }

          &:active {
            background-color: #2a54e8;
          }
        }
      }

      .m-menu.z-selected {
        color: $baseBlue;
      }

      .u-btn {
        color: #fff;
        background-color: $baseBlue;
      }

      .m-menu.z-selected::after {
        background-color: $baseBlue;
      }

      .g-l .g-l:nth-child(2) {
        .main-activity {
          color: $baseBlue;

          &::after {
            position: absolute;
            content: " ";
            width: 25px;
            height: 3px;
            background-color: $baseBlue;
            left: 50%;
            margin-left: -12px;
            bottom: 3px;
          }
        }
      }
    }
  }
}
</style>
